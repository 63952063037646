@media (max-width: 1300px) {}

@media (max-width: 1120px) {}

@media (max-width: 992px) {
  .hero_area {
    min-height: auto;
  }

  .custom_nav-container .navbar-nav {
    padding-top: 15px;
  }

  .custom_nav-container .navbar-nav .nav-item .nav-link {
    padding: 5px 25px;
    margin: 5px 0;
  }

  .slider_section .detail-box h1 {
    font-size: 2.5rem;
  }

}

@media (max-width: 767px) {

  .header_section .header_top .social_box {
    display: none;
  }

  .header_section .header_top .contact_nav a span {
    font-size: 15px;
  }


  .slider_section .carousel_btn-box {
    margin-top: 45px;
    justify-content: center;
  }

  .slider_section .carousel_btn-box a {
    position: unset;
    transform: none;
    opacity: 1;
    margin: 0 5px;
  }

  .about_section .img-box,
  .faq_section .detail-box {
    margin-bottom: 45px;
  }

  .call_section .img-box {
    margin-bottom: 30px;
  }

}

@media (max-width: 576px) {
  .header_section .header_top .contact_nav a span {
    display: none;
  }

  .header_section .header_top .contact_nav a.call-link span {
    display: inline-flex;
  }

  .call_section .detail-box h3 {
    font-size: 2.5rem;
  }
}

@media (max-width: 520px) {
  .blog_section .box {
    flex-direction: column;
  }

  .blog_section .box .img-box {
    width: 100%;
  }
}

@media (max-width: 480px) {}

@media (max-width: 420px) {}

@media (max-width: 376px) {}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}