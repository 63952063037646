/* table styles */
.tablecontainers {
    width: 100%;
    overflow-x: auto;
    /* filter: drop-shadow(0px 0px 50px #263d611a); */
    padding: 0px 10px;
  }
  
  .tablecontainers::-webkit-scrollbar {
    width: 10px;
  }
  
  .tablecontainers::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  
  .tablecontainers::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  

  
  @media (min-width: 769px) {
    .tablecontainers {
      overflow-y: auto;
      height: calc(100vh - 400px);
    }
  }

 
  
  .tableheadrow {
    width: 100%;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-family: productsans-bold;
    font-weight: bold;
    color: #4f4562;
    font-size: 18px;
    line-height: 22px;
    background: #e4deee;
  }
  
  .tablerow {
    width: 100%;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
  }
  
  .tableitem:nth-child(1) {
    font-family: productsans-bold;
  }
  
  .tableitem {
    font-family: productsans-regular;
    color: "blue";
    font-style: normal;
    font-weight: normal;
    border: none;
    padding: 20px;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    vertical-align: middle;
  }
  .tableitemtitle {
    border: none;
    padding: 20px;
    font-weight: 900;
    font-size: 0.8rem;
    font-size: clamp(0.5rem, 1.5vw, 0.8rem);
  
    /* background: rebeccapurple; */
  }
  
  .tablehead {
    text-align: left;
    border: none;
    padding: 20px;
    font-weight: 600;
    font-size: 1em;
    position: sticky;
    top: 0;
  }
  
  .separatetable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px;
  }
  
  .collapsetable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 15px;
  }
  
  @media (min-width: 768px) {
    .tablerow td:nth-child(1) {
      border-radius: 10px 0 0 10px;
    }
  
    .tablerow td:nth-last-child(1) {
      border-radius: 0 10px 10px 0;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .separatetable,
    .tablehead,
    .tableitem,
    .tablerow,
    .tablebody,
    .collapsetable {
      display: block;
      text-align: left !important;
    }
  
    .tablehead {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    .tablerow {
      margin: 0 0 1rem 0;
      width: 100%;
    }
  
    .tablecontainer {
      overflow: hidden;
    }
  
    .tableitem {
      padding: 10px;
    }
  
    .tableitem:before {
      content: attr(data-label);
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
    }
  
    .mobiletableitem {
      text-align: left !important;
      border: none;
      padding: 20px;
      font-weight: 400;
      font-size: 0.8em;
    }
  }