:root {
  --toastify-color-progress-light: #4834b2 !important;
}

* {
  font-family: "Open Sans", sans-serif;
}

.vl {
  border-left: 1px solid rgba(168, 168, 168, 0.5);
  height: 13em;
}

.bg-light {
  background-color: #ebeaf0 !important;
}

.Prussian-Blue-color {
  color: #00334e;
}

.OceanBlue-color {
  color: #4834b2;
}

.font-color {
  color: #4d31e3 !important;
}

.color-wht {
  color: #fff !important;
}

.color-dark {
  color: #333 !important;
}

.bg-white {
  background-color: #fff;
}

.icon-bg-3 {
  background-color: #2a2a4a;
}

.icon-bg-4 {
  background-color: #d3eef4;
}

.icon-bg-5 {
  background-color: #ecebf4;
}

.icon-bg-6 {
  background-color: #f2f0f8;
}

.subtext-color {
  color: #666;
}

.OceanBlue-bg {
  background-color: #4834b2;
}

.input-bg {
  background-color: rgba(231, 229, 235, 0.9);
}

.arrow-bg {
  background-color: #745bff;
}

.bg-color-2 {
  background: rgb(217, 217, 227);
  background: linear-gradient(
    150deg,
    rgba(217, 217, 227, 1) 0%,
    rgba(239, 239, 244, 1) 100%
  );
}

.bg-color-3 {
  background: rgb(141, 141, 184);
  background: linear-gradient(
    270deg,
    rgba(141, 141, 184, 1) 0%,
    rgba(41, 41, 70, 1) 100%
  );
}

.bg-color-4 {
  background: rgb(122, 210, 231);
  background: linear-gradient(
    180deg,
    rgba(122, 210, 231, 1) 0%,
    rgba(76, 179, 204, 1) 100%
  );
}

.bg-color-5 {
  background-color: #e2ddff;
}

.bg-color-6 {
  background-color: #423e5b;
}

.color-2 {
  color: #423e5b;
}

.button-color-2 {
  background-color: #585472;
}

.button-bg-3 {
  background: rgb(116, 91, 255);
  background: linear-gradient(
    -90deg,
    rgba(116, 91, 255, 1) 0%,
    rgba(95, 73, 214, 1) 100%
  );
}

.fw-os-light {
  font-weight: 300;
}

.fw-os-rg {
  font-weight: 400;
}

.fw-os-sb {
  font-weight: 600;
}

.fw-os-b {
  font-weight: 700;
}

.fw-os-xb {
  font-weight: 800;
}

.fs-09 {
  font-size: 0.9em;
}

.fs-st {
  font-size: 0.7em;
}

.fs-08 {
  font-size: 0.8em;
}

.fs-10 {
  font-size: 1.1em;
}

.fs-large {
  font-size: 3em;
}

.button-border {
  border-radius: 13px;
}

.button-radius-1 {
  border-radius: 26px;
}

.button-border-start {
  border-radius: 13px 0px 0px 13px;
  padding: 0px;
}

.letter-spacing {
  letter-spacing: 0.9px;
}

.text-decoration {
  text-decoration: none;
  cursor: pointer;
}

.shadow-sm {
  box-shadow: 0px 0px 20px rgb(235, 235, 235) !important;
}

.btn-radius-o {
  border-radius: 50px;
}

.center {
  display: flex;
  justify-content: center;
}

.between {
  display: flex;
  justify-content: space-between;
}

.icon-radius {
  border-radius: 37px;
}

.icon-padding {
  padding: 13px 13px;
}

.img-padding {
  height: auto;
  width: auto;
  padding: 6px;
}

.hover-on-1 :hover {
  background-color: rgb(245, 245, 245) !important;
  color: #333 !important;
  box-shadow: 0px 0px 20px rgb(194, 194, 194) !important;
}

.hover-on-text :hover {
  color: #745bff;
}

.input-icon-position {
  position: absolute;
  left: 13px;
  top: 30%;
  color: gray;
}

.card-border-radius-top {
  border-radius: 90px 90px 0px 0px;
}

.card-border-radius-bottom {
  border-radius: 0px 0px 90px 90px;
}

.platinum-bg-color {
  background: rgb(244, 236, 234);
  background: radial-gradient(
    farthest-corner at 50% 110%,
    rgba(244, 236, 234, 1) 0%,
    rgba(214, 207, 204, 1) 34%,
    rgba(182, 176, 173, 1) 82%,
    rgba(186, 185, 181, 1) 100%
  );
}

.gold-bg-color {
  background: rgb(248, 237, 206);
  background: radial-gradient(
    farthest-corner at 50% 110%,
    rgba(248, 237, 206, 1) 0%,
    rgba(229, 198, 115, 1) 34%,
    rgba(197, 139, 18, 1) 82%,
    rgb(243, 212, 125) 100%
  );
}

.silver-bg-color {
  background: rgb(242, 242, 242);
  background: radial-gradient(
    farthest-corner at 50% 110%,
    rgba(242, 242, 242, 1) 0%,
    rgba(227, 227, 227, 1) 34%,
    rgba(197, 197, 197, 1) 82%,
    rgba(233, 233, 233, 1) 100%
  );
}

.btn-radius-pack {
  border-radius: 100px 100px 90px 90px;
}

.on-sale {
  border: 2px solid #f3ca3e;
  border-radius: 14px;
  color: #fa9917;
}

.card-list-bolt {
  list-style-type: none;
}

.color-success {
  color: #2ac940;
}

.margin-10px {
  margin-left: 1px;
  margin-right: 1px;
}

input {
  color: #222 !important;
}

input:focus-visible {
  box-shadow: 0 0 2pt 1pt #00000077;
  outline: none !important;
  transition: all 0.25s ease-in-out;
}

.error-shadow {
  box-shadow: 0 0 2pt 1pt #f32013;
}

.PhoneInputInput {
  border: none;
  background: transparent;
  box-shadow: none !important;
}

.error-feedback {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #f32013;
}

.location-item {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.location-item:hover {
  background-color: #aaaaaa22;
  transition: all 0.25s ease-out;
}

.location-item-container {
  max-height: 300px;
  overflow-y: scroll;
}

.map-item-container {
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #4834b255;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #4834b2;
}

.marker-pin-center {
  position: absolute;
}

.luggage-table-header {
  background-color: #eeeeee88;
}

.luggage-table-input {
  height: 30px;
  margin-bottom: 0px !important;
  border-bottom: 2px solid #4834b255;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  width: 20%;
  margin-left: 5%;
  text-align: center;
}

.luggage-table-input:focus-visible {
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer;
}

.luggage-table-input::placeholder {
  color: #999;
}

.luggage-table-add {
  width: 40px;
}

.luggage-table-details {
  background-color: #eeeeee88;
  margin-left: 4px;
  margin-right: 4px;
  padding: 10px;
}

.prev-button {
  width: 120px;
}

.box-table-select {
  width: 80%;
}

.top-navbar-moversly {
  box-shadow: 0 10px 10px -10px #00000044;
}

/* Card */

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.link {
  color: #2200cc;
  cursor: pointer;
}

.box {
  border: #aaa 2px solid;
}

.privacy-heading {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin: 20px 0;
}

.privacy-sub-heading {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin: 20px 0;
}

.privacy-content {
  color: #555;
  margin-bottom: 50px;
  text-align: justify;
}

@media only screen and (max-width: 500px) {
  .mobile-date-picker {
    width: 330px;
    height: 50px;
  }
}

.button-custom {
  min-width: 150px;
}

.mobile-profile-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-navbar-profile-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.contact-up-resposive {
  width: 60%;
}

@media screen and (max-width: 990px) {
  .contact-up-resposive {
    width: 90%;
  }
}

.alert-poisiton {
  width: 45%;
  position: fixed;
  bottom: 20px;
}
.listings {
  margin-bottom: 20px;
}

.errors {
  border: 1px solid red;
}

.price{
  text-align: center;
  color: red;
 /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5) !important; */
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  margin-top: 10px;

}
