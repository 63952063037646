.card {
  position: inherit;
  border-radius: 10px;
  margin: 10px 0px;
  padding: 20px 0px 55px 0px;
}
.profiles  {
  display: flex;
  padding: 0px 20px;
}
.profiles_icon {
  color: #a495c7;
  font-size: 40px;
  margin-right: 7px;
}
.profile_names {
  line-height: 0px;
}
.profile_names > p {
  color: #a098b8;
  font-family: "Open Sans";
  font-size: 12px;
}
.profile_names > h4 {
  color: #4e3883;
  font-family: "Open Sans Semibold";
  font-size: 18px;
  text-transform: capitalize;
  /* line-height: 25px; */
}
.details {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  line-height: 0px;
  font-family: "Open Sans";
}
.requestId > h6 {
  color: #1f52da;
  text-decoration: underline;
  letter-spacing: 1px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
}
.requestId > p {
  color: #a098b8;

  font-size: 13px;
}
.volume > h6 {
  letter-spacing: 1px;
  font-size: 14px;
  padding-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  color: #4e3883;
}
.volume > p {
  color: #a098b8;
  font-size: 13px;
}
.container {
  margin-left: 20px;
  padding-top: 10px;
}
.container > h6 {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #4e3883;
  font-size: 14px;
}
.container > p {
  color: #a098b8;
  font-size: 12px;
  white-space: normal;
}
.date {
  margin-left: 30px;
}
.date > h6 {
  color: #4e3883;
  font-size: 14px;
}
.date > p {
  color: #a098b8;
  font-size: 13px;
  width: 80px;
}
.shipment_mode > h6 {
  font-family: "Open Sans";
  color: #4e3883;
}
.shipment_mode {
  color: #a098b8;
  font-size: 12px;
}
.status {
  line-height: 17px;
}
.status > span {
  box-sizing: border-box;
  padding: 1px 15px;
  height: 20px;
  width: 100px;
  border-radius: 8px;
  border: 1px solid #ff2e2e;
  font-size: 10px;
  color: #ff2e2e;
}
.status > p {
  color: #a098b8;
  font-size: 12px;
  text-align: right;
}
.location {
  display: flex;
  background: #fcfaff;
  height: 80px;
}

.circle {
  border: 1px solid gray;
  border-radius: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dot {
  background: #2d234c;
  width: 10px;
  height: 10px;
  border-radius: 7px;
}
.line {
  width: 0px;
  height: 25px;
  border: 0.2px dashed #2d234c;
}
.from_city {
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans";
  margin-left: -10px;
  color: #4e3883;
  text-transform: capitalize;
}
.from_city > span {
  font-size: 12px;
  margin-left: 5px;
  color: #4e3883;
}
.to_city {
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans";
  margin-left: -10px;
  color: #4e3883;
}
.to_city > span {
  font-size: 12px;
  margin-left: 5px;
  color: #4e3883;
}

.info {
  padding: 0px 20px;
  display: flex;
  align-items: center;
}
.info_icon {
  color: #1f52da;
  font-size: 20px;
}
.info_icon1 {
  color: #f68d0e;
  font-size: 20px;
}
.info_icon2 {
  color: #88d71a;
  font-size: 20px;
}
.info_icon3 {
  color: #a109b8;
  font-size: 20px;
}
.info > span {
  font-size: 12px;
  margin-left: 5px;
  color: #a098b8;
  font-family: "Open Sans";
}
.btn-edit {
  color: #fff;
  background-color: #4e3883;
  /* border-color: #4e3883; */
  border: "none";
  outline: none;
}
.btn-edit:hover {
  color: #fff;
}
.btn-view {
  color: #4e3883;
  border-color: #4e3883;
}
.card-btn{
  padding-top: 1rem;
}

@media screen and (min-width: 150px) and (max-width: 600px) {
  .date {
    margin-left: 0px !important;
  }
  .details {
    display: block;
  }
  .card {
    margin: 10px 0px 60px 0px;
  }
  .status > p {
    text-align: left;
  }
}
